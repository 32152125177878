<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Proyek</label>
        <vs-input class="w-full" :value="initData.reimburse.nama_proyek" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">No. Reimburse</label>
        <vs-input class="w-full" :value="initData.reimburse.no_reimburse" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tipe</label>
        <vs-input class="w-full" :value="initData.reimburse.tipe" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tanggal</label>
        <vs-input class="w-full" :value="initData.reimburse.tgl_reimburse" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Diajukan Oleh</label>
        <vs-input class="w-full" :value="initData.reimburse.nama_staf" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Created By</label>
        <vs-input class="w-full" :value="initData.reimburse.username" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Total Nominal</label>
        <v-money class="w-full" :value="initData.reimburse.total_reimburse" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Judul</label>
        <vs-input class="w-full" :value="initData.reimburse.judul_reimburse" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Keterangan</label>
        <vs-input class="w-full" :value="initData.reimburse.keterangan" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3 mt-base">
      <div class="vx-col sm:w-12/12 w-full">
        <vs-table :data="initData.reimburseD" class="fix-table-input-background">
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">#</vs-th>
            <vs-th class="whitespace-no-wrap">Item Reimburse</vs-th>
            <vs-th class="whitespace-no-wrap">No. Nota</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl. Nota</vs-th>
            <vs-th class="whitespace-no-wrap">Bukti Nota</vs-th>
            <vs-th class="whitespace-no-wrap">Jumlah</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.nama_item_pengadaan }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.no_nota }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.tgl_nota }}</vs-td>
              <vs-td class="whitespace-no-wrap">
                <span v-if="item.files_url.length > 0" class="text-xs underline cursor-pointer" @click="showFiles(item)">Lihat Files</span>
                <span v-else class="text-sm">-</span>
              </vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.jml_reimburse | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.keterangan }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <!--modals-->
    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>
  </div>
</template>

<script>
import FilesViewer from '@/views/components/files-viewer/FilesViewer'
import VMoney from '@/views/components/v-money/VMoney'

export default {
  name: 'TabDetail',
  props: ['isActive', 'initData'],
  components: {
    FilesViewer,
    VMoney
  },
  data () {
    return {
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  },
  methods: {
    showFiles (item) {
      this.modalFiles.filesUrl = item.files_url
      this.modalFiles.active = true
    }
  }
}
</script>
